import Vue from "vue";
import App from "./App.vue";
import store from "./store";

// import {load, Map, Marker} from 'vue-google-maps'
// import { load } from "vue-google-maps";
// const VueGoogleMap = require('vue-google-maps')

//--import-axios
import axios from 'axios'
import VueAxios from 'vue-axios'

//import longdo-map
// https://github.com/MetamediaTechnology/longdo-map-vue
import LongdoMap from 'longdo-map-vue'

import "./app.css";
// build-in-prod
// https://tailwindcss.com/docs/installation#building-for-production

// // https://vue-tailwind-picker.netlify.app/usage/#vuejs
// import VueTailwindPicker from 'vue-tailwind-picker'

Vue.config.productionTip = false;

// Vue.use(VueTailwindPicker)

// load({
//   key: "AIzaSyAVKIUV35VC_NOoJhjIPPjfQwJAuw_uusM",
//   // v: "3.24", // Google Maps API version
//   // https://developers.google.com/maps/documentation/javascript/versions
//   v: "quarterly", // Google Maps API version
//   // libraries: 'places',   // If you want to use places input
// });

// VueGoogleMap.load({
//   'key': 'AIzaSyAVKIUV35VC_NOoJhjIPPjfQwJAuw_uusM',
// })

//gg-map
// dev-key
// https://github.com/googlemaps/google-maps-services-js
// , install-ggMap
// https://developers.google.com/maps/documentation/javascript/overview?hl=th#js_api_loader_package

//--use-map
Vue.use(LongdoMap, {
  load: {
    apiKey: '24125213d9c3b40da818c03eae7be940'
  }
})

//--use-axios
Vue.use(VueAxios, axios)

window.myVue = new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
