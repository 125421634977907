<template>
  <div class="h-screen w-full relative mt-0 pt-0" id="app">
    <!-- --try--tilwindCss -->
    <!-- <div class="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
      <div class="flex-shrink-0">
        <img class="h-12 w-12" src="/img/logo.svg" alt="ChitChat Logo" />
      </div>
      <div>
        <div class="text-xl font-medium text-black">ChitChat</div>
        <p class="text-gray-500">You have a new message!</p>
      </div>
    </div>-->
    <!-- --end-try--tilwindCss -->
    <!-- --try--longdoMap -->
    <!-- <div>google-map........</div> -->
    <!-- <div id="gg-map"></div> -->
    <!-- <PopupHasSub v-if="false" /> -->
    <!-- <map :center="center" :zoom="7">
      <marker
        v-for="(m,index) in markers"
        :key="index"
        :position.sync="m.position"
        :clickable="true"
        :draggable="true"
        @g-click="center=m.position"
      ></marker>
    </map>-->
    <!-- --end-try--longdoMap -->
    <div class="flex flex-col h-full w-full absolute bottom-0 left-0 z-0">
      <div class="w-full bg-white">
        <div class="w-full flex px-2 pt-1">
          <!-- <div class="marquee">
            <span>
              📋 ยอดสะสม 4297 เพิ่มขึ้น 16 / เสียชีวิต 60 / หายแล้ว 4005 – 20 ธันวาคม 2020 แหล่งข่าว
              📋 ยอดสะสม 4297 เพิ่มขึ้น 16 / เสียชีวิต 60 / หายแล้ว 4005 – 20 ธันวาคม 2020 แหล่งข่าว
              📋 ยอดสะสม 4297 เพิ่มขึ้น 16 / เสียชีวิต 60 / หายแล้ว 4005 – 20 ธันวาคม 2020 แหล่งข่าว
              Fast Scrolling
            </span>
          </div>-->
          <div class="w-11/12 z-10 inline-block py-0 pr-1">
            <!-- <p v-show="!hasTextRunningData" class="inline">ยังไม่มีข้อมูล</p> -->
            <!-- <marquee class="py-0 my-0 w-11/12 inline-block">111111111111112222222222222333333333333333</marquee> -->

            <!-- <div class="p-0 m-0" v-show="hasTextRunningData"> -->
            <div class="p-0 m-0">
              <marquee class="py-0 my-0 inline" behavior="scroll" direction="left" scrollamount="2">
                <div v-show="!hasTextRunningData">ยังไม่มีข้อมูล</div>
                <div v-show="hasTextRunningData">
                  📋
                  <b>ยอดสะสม {{ this.textRunning.total }} ราย</b>
                  เป็นพลทหาร {{ this.textRunning.pol }} ราย / พลเรือนกำลังพลและครอบครัว {{ this.textRunning.kam }} ราย –
                  <i>ข้อมูลตั้งแต่ {{ this.textRunning.date_start }} ถึง {{ this.textRunning.date_end }}</i>
                </div>
              </marquee>
            </div>
          </div>

          <div class="z-20 py-1 absolute right-0 bg-white top-0 my-0 inline-block">
            <div @click="toggleMoreInfo" class="inline cursor-pointer">
              <!-- <hr /> -->
              <span class="text-blue-500">ดูข้อมูลเพิ่ม</span>
              <span v-show="!toggleShowMorwInfo" class>▾</span>
              <span v-show="toggleShowMorwInfo" class>▴</span>
            </div>|
            <div @click="toggleSetting" class="inline cursor-pointer pr-1">
              <span class="font-bold">ตั้งค่า</span>
              <span v-show="!toggleShowSettings" class>▾</span>
              <span v-show="toggleShowSettings" class>▴</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="w-full px-2 py-0">
          <div v-show="toggleShowSettings" class="date-filter inline">
            <!-- <hr /> -->
            <h2>
              <b>Filter:</b>
            </h2>
            <div class="date-filter-group inline">
              <label>
                <span class="pr-3">แสดงข้อมูลในช่วง</span>
              </label>
              <label>
                <input
                  value="today"
                  type="radio"
                  name="show-date-today"
                  @change="changeMode"
                  v-model="showDateMode"
                />วันนี้
              </label>
              |
              <label>
                <input
                  value="month"
                  type="radio"
                  name="show-date-month"
                  @change="changeMode"
                  v-model="showDateMode"
                />เดือนนี้
              </label>
              |
              <div :class="['custom-date inline-block', {'outline-black':isShowDateCustom}]">
                <!-- <div class="custom-date inline-block"> -->
                <label>
                  <input
                    value="custom"
                    type="radio"
                    name="show-date-custom"
                    @change="changeMode"
                    v-model="showDateMode"
                  />เลือกช่วงเวลาเอง
                </label>
                <div class="show-custom-date inline-block" v-show="isShowDateCustom">
                  ::
                  ตั้งแต่
                  <label class="inline-block pl-1 pr-1">
                    <!-- <input type="date" name="show-date-custom-sdate" id="show-date-custom-sdate" value="2020-01-31" /> -->
                    <input
                      type="date"
                      :class="['w-36', {'bg-green-200':startDate_validator,'bg-red-300':!startDate_validator}]"
                      name="show-date-custom-sdate"
                      id="show-date-custom-sdate"
                      v-model="showDateRange.showStartDate"
                    />
                    <!-- <VueTailwindPicker
                    class="inline-block"
                    :init="false"
                    :start-date="dateRangeStart"
                    :end-date="dateRangeLimit"
                    @change="(v) => showDateRange.showStartDate = v"
                  >
                    <input
                      class="bg-green-200 w-20"
                      type="text"
                      v-model="showDateRange.showStartDate"
                    />
                    </VueTailwindPicker>-->
                  </label>
                  ถึง
                  <label class="inline-block pl-1">
                    <input
                      type="date"
                      :class="['w-36', {'bg-green-200':endDate_validator,'bg-red-300':!endDate_validator}]"
                      name="show-date-custom-edate"
                      id="show-date-custom-edate"
                      v-model="showDateRange.showEndDate"
                    />
                    <!-- <VueTailwindPicker
                    class="inline-block"
                    :init="false"
                    :start-date="dateRangeStart"
                    :end-date="dateRangeLimit"
                    @change="(v) => showDateRange.showEndDate = v"
                  >
                    <input
                      :class="['w-20', {'bg-green-200':endDate_validator,'bg-red-300':!endDate_validator}]"
                      type="text"
                      v-model="showDateRange.showEndDate"
                    />
                    </VueTailwindPicker>-->
                  </label>
                </div>
              </div>

              <label>
                |
                <input
                  value="all"
                  type="radio"
                  name="show-date-all"
                  @change="changeMode"
                  v-model="showDateMode"
                  checked
                />ทั้งหมด
              </label>
            </div>
            <div
              class="border-black border border-solid setup-number-warning-icon ml-3 inline-block"
            >
              <label>
                เตือนด้วยไอคอนเมื่อ(จำนวน):
                <input
                  class="text-right"
                  type="number"
                  min="1"
                  :max="cssFilterWarningIconSizeMax"
                  name="filter-warning-icon"
                  @change="changeFilterWarningIcon"
                  @keyup.enter="changeFilterWarningIcon"
                  v-model="envStateFilterWarningIcon"
                /> ราย
              </label>
            </div>
            <hr />
            <div class="set-sheet-url border-black border border-solid ml-3 inline-block">
              <label>
                sheet-url:
                <input
                  class="w-15 border-black border border-solid"
                  type="text"
                  @keyup.enter="onUserUpdateUrl"
                  @keydown.esc="envSheetUrl = ''"
                  @focus="validateSheetUrl = true"
                  v-model="envSheetUrl"
                />
                <button
                  class="px-3 py-1 mx-2 text-blue-600 font-semibold hover:bg-blue-700 hover:text-white border border-blue-600 hover:border-transparent rounded-full"
                  @click="onUserUpdateUrl"
                >save</button>
              </label>
              <label class="text-red-600" v-show="showSyncErr">Sync Error!!!</label>
              <label class="text-green-600" v-show="showSyncLoading">Sync Loading...</label>
              <button
                class="uppercase px-2 mr-2 py-2 rounded bg-green-300 text-green-600 max-w-max shadow-sm hover:shadow-lg"
                @click="onUserSyncData"
              >sync-data</button>
              <button
                class="uppercase px-2 py-2 rounded bg-gray-300 text-gray-600 max-w-max shadow-sm hover:shadow-lg"
                @click="onUserSyncLocation"
              >sync-location</button>
            </div>
            <hr class="pt-1" />
          </div>

          <div v-show="toggleShowMorwInfo">
            <h2>
              <b>สรุปข้อมูล:</b>
            </h2>
            <ul>
              <li>
                สัปดาห์ผ่านมา({{ textDayOfWeekRange }}): รวม
                <span
                  class="font-medium italic"
                >{{ textRunning.currentWeek }} ราย</span>
              </li>
              <li>
                สัปดาห์ที่แล้ว(จ-อา): รวม
                <span
                  class="font-medium italic"
                >{{ textRunning.previousWeek }} ราย</span>
              </li>
              <li>
                ผู้ป่วยใหม่เพิ่มขึ้น/ลดลง จากสัปดาห์ก่อน:
                <span class="font-semibold italic">
                  ป่วยใหม่
                  <span :class="['font-semibold italic', cssDiffWeek ]">{{ textDiffWeek }}</span>
                  ราย
                </span>
              </li>
              <!-- จ/อ/พ/พฤ/ศ/ส/อา -->
              <!-- https://th.wikipedia.org/wiki/%E0%B9%81%E0%B8%A1%E0%B9%88%E0%B9%81%E0%B8%9A%E0%B8%9A:%E0%B8%AB%E0%B8%A3%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%88%E0%B8%B9%E0%B9%80%E0%B8%A5%E0%B8%B5%E0%B8%A2%E0%B8%99.%E0%B8%A7%E0%B8%B1%E0%B8%99%E0%B9%83%E0%B8%99%E0%B8%AA%E0%B8%B1%E0%B8%9B%E0%B8%94%E0%B8%B2%E0%B8%AB%E0%B9%8C -->
              <!-- <li>7 วันที่ผ่านมา(วันที่ 8-14): รวม 33 ราย</li> -->
              <!-- <li>7 วันที่แล้ว(วันที่ 1-7): รวม 33 ราย</li> -->
            </ul>
          </div>
          <!-- <input type="checkbox" name="xx" id="xx" /> -->
          <!-- <label for="xx">xx</label> -->
          <hr />
        </div>
      </div>
      <!-- <longdo-map ref="map007"></longdo-map> -->
      <!-- <longdo-map ref="map007" :zoom="9"></longdo-map> -->
      <!-- <div class="h-full w-full relative"  id="map"></div> -->
      <!-- <div :class="['h-full', 'w-full', {'longdo-map':computeMap}]" id="longdo-map"></div> -->
      <div class="h-full w-full" id="longdo-map"></div>
    </div>
    <div class="md:w-5/12 w-11/12 absolute bottom-8 left-0 z-10">
      <div class="bg-white mx-0 pl-4 py-2 my-2 rounded-r-lg w-full">
        <div
          @click="locateMe"
          class="p-1 icon absolute -top-5 right-0 bg-white rounded-full cursor-pointer"
        >
          <img class="align-top" src="/assets/location.png" alt="locate me" height="24" />
          <span>home-position</span>
        </div>
        <h1 class="text-lg inline text-gray-800">แผนที่ข่าว COVID-19 🔍</h1>
        <div @click="toggleIconDetail" class="flex cursor-pointer text-gray-800 items-center">
          <span class="mr-1 font-semibold text-sm">👀 ดูความหมายของไอคอน</span>
          <span v-show="!isShowIconDetail" class>▾</span>
          <span v-show="isShowIconDetail" class>▴</span>
        </div>
        <!-- --iconDetail -->
        <div
          v-show="isShowIconDetail"
          class="p-3 mr-9 mt-2 flex border-solid border-2 rounded-lg border-gray-600"
        >
          <div class="flex-1">
            <div class="mb-3 font-semibold text-xs text-gray-800">สถานะข่าว</div>
            <div class="items-center flex mb-2">
              <div class="w-4 h-4 flex items-center justify-center">
                <img
                  class="align-top"
                  src="/assets/images/dot.svg"
                  width="10"
                  height="10"
                  alt="pic"
                />
              </div>
              <!-- <span class="ml-1 text-sm text-gray-900">ได้รับการยืนยัน</span> -->
              <span class="ml-1 text-sm text-gray-900">มีผู้ป่วยไม่มาก</span>
            </div>
            <div class="items-center flex mb-2">
              <img
                class="align-top"
                src="/assets/images/confirmed.svg"
                width="16"
                height="16"
                alt="pic"
              />
              <!-- <span class="ml-1 text-sm text-gray-900">ได้รับการยืนยัน</span> -->
              <span class="ml-1 text-sm text-gray-900">มีผู้ป่วยมากกว่า 10 ราย</span>
            </div>
          </div>
          <div class="flex-1">
            <!-- <div class="mb-3 font-semibold text-xs text-gray-800">สถานะผู้ป่วย</div> -->
            <div class="mb-3 font-semibold text-xs text-gray-800">ประเภทผู้ป่วย</div>
            <div class="mb-2 flex items-center">
              <img
                class="align-top"
                src="/assets/images/recovered.svg"
                width="16"
                height="16"
                alt="pic"
              />
              <!-- <span class="ml-1 text-xs text-green-400">รักษาหายแล้ว</span> -->
              <span class="ml-1 text-xs text-green-400">พลทหาร</span>
            </div>
            <div class="mb-2 flex items-center">
              <img
                class="align-top"
                src="/assets/images/hospitalized.svg"
                width="16"
                height="16"
                alt="pic"
              />
              <!-- <span class="ml-1 text-xs text-yellow-500">กำลังรักษา</span> -->
              <span class="ml-1 text-xs text-yellow-500">กำลังพลและครอบครัว</span>
            </div>
            <!-- <div class="mb-2 flex items-center">
            <img
              class="align-top"
              src="./assets/images/deceased.svg"
              width="16"
              height="16"
              alt="pic"
            />
            <span class="ml-1 text-xs text-gray-800">ยอดรวม</span>
            </div>-->
            <!-- <span class="ml-1 text-xs text-gray-800">เสียชีวิต</span> -->
            <!-- <div class="mb-2 flex items-center">
            <img
              class="align-top"
              src="./assets/images/transferred.svg"
              width="16"
              height="16"
              alt="pic"
            />
            <span class="ml-1 text-xs text-blue-400">ส่งตัวต่อเพื่อทำการรักษา</span>
            </div>-->
            <!-- <div class="mb-2 flex items-center">
            <img
              class="align-top"
              src="./assets/images/unknown.svg"
              width="16"
              height="16"
              alt="pic"
            />
            <span class="ml-1 text-xs text-gray-400">ไม่ทราบสถานะ</span>
            </div>-->
          </div>
        </div>
        <!-- --end-iconDetail -->
        <!-- --by -->
        <div class="pt-2 pb-1 justify-between flex items-center">
          <div class="text-gray-800 flex-1 text-xs">
            By
            <a href="http://www.ananhosp.go.th/" target="_blank" class="text-red-400">
              <span class="border-solid border-b-1">⚡ananhosp&nbsp;</span>
            </a>
            <span class="inline-block">
              and
              <a class="inline" href="http://www.tru.ac.th/">
                <span class="text-blue-700 border-solid border-b-1">TRU</span>.
              </a>
            </span>
          </div>
        </div>
        <!-- <button @click="tryOnly" class="bg-gray-300">add-marked</button> -->
        <!-- <div v-show="isShowXoxo" id="hideComp" cls style="background: #eeeeff;">
        <popup-has-sub :list_sub_obj="listArr" />xxx
        </div>-->
        <!-- <div id="xoxo">xoxo</div> -->

        <!-- --end-by -->
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
// import PopupHasSub from "./components/PopupHasSub.vue";
// import dataSummary from "@/assets/data/summary.json";
// import Vue from "vue";

// import VueTailwindPicker from "vue-tailwind-picker";
import dayjs from "dayjs";

// https://stackoverflow.com/questions/57538539/how-to-use-enums-or-const-in-vuejs
const ENUM_FILTER_MODE = Object.freeze({
  default: "all",
  all: "all",
  today: "today",
  week: "week",
  month: "month",
  custom: "custom"
});
const ENUM_ICON_SIZE = Object.freeze({
  x1: 1,
  x2: 2,
  x3: 3
});

const ENUM_RENDER_MARKER_TYPE = Object.freeze({
  main: "main",
  sub: "sub"
});

export default {
  name: "App",
  components: {
    // HelloWorld
    // PopupHasSub
    // VueTailwindPicker
  },
  data: () => ({
    // --config api-path
    // pathApiURL: "http://35.232.232.114",
    // pathApiURL: "http://localhost:8000",
    pathApiURL: "//" + process.env.VUE_APP_ENV_IP,
    pathSummaryMap: "/summary",
    pathTextRunning: "/summary-slide",
    pathSetUrl: "/setting-sheet-url",
    pathSyncData: "/sync-data",
    pathSyncLocation: "/sync-location",

    lastDataRes: {},

    //--testRunning
    textRunning: {
      total: 0,
      pol: 0,
      kam: 0,
      currentWeek: 0,
      currentDayOfWeek: "unknow",
      previousWeek: 0,
      diffWeek: 0,
      date_start: null,
      date_end: null
    },
    //--filter
    envStateFilterWarningIcon: 5,
    // TODO: scale-size-max
    // https://stackoverflow.com/questions/22709792/input-type-number-wont-resize
    cssFilterWarningIconSizeMax: 99999,
    envSheetUrl: "",
    validateSheetUrl: true,
    showSyncErr: false,
    showSyncLoading: false,

    //--init-map
    loadedWindow: false,
    lastReload: 0,

    //location-center
    // centerLocation: { lon: 100.82672, lat: 15.09505 },
    // defaultLocation = 7/11 โรงบาล
    centerLocation: { lon: 100.665269, lat: 14.849835 },

    // --marker-list
    listMarkerRendered: [],

    // zoomLevelIsSubUnit: 15,
    zoomIconSize: ENUM_ICON_SIZE.x1,

    //--popup-list
    // marker01: {},
    // popup01: {
    //   closable: true,
    //   size: "100px",
    //   html:
    //     '<div class="xx01"><div class="close-xx01" onclick="closePopup(\'close-xx01\')">close-xx01</div><img src="/icons/loading/Eclipse-1s-200px.svg" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>'
    // },

    //--toggle-settings
    toggleShowSettings: false,
    toggleShowMorwInfo: false,

    //--show-summary-logic
    // showDateMode: "all",
    showDateMode: ENUM_FILTER_MODE.default,
    showDateRange: {
      showStartDate: null,
      showEndDate: null
    },
    endDate_validator: true,
    startDate_validator: true,

    //--date-picker--tailwind
    checkin: "",

    //--
    isShowIconDetail: false,
    map: null,
    //for-remove object-overlay
    edgeObjectLopburi: null,
    //--js->vueComp
    uniqueMyCon: 1,
    xoxo: null,
    isShowXoxo: false,
    hc: null,
    listArr: [1, 2, 5]
  }),
  computed: {
    // // --initMap
    // computeMap() {
    //   if (this.loadedWindow) {
    //     this.onWindowLoad();
    //   }
    //   return "longdo-map";
    // },

    isShowDateCustom() {
      return this.showDateMode == "custom";
    },

    hasTextRunningData() {
      if (this.textRunning.total > 0) return true;
      return false;
    },

    cssDiffWeek() {
      if (this.textRunning.diffWeek > 0) {
        // +คือ ป่วยเพิ่ม
        return "text-red-700";
      } else if (this.textRunning.diffWeek < 0) {
        return "text-green-700";
      }
      return "text-black";
    },
    textDiffWeek() {
      if (this.textRunning.diffWeek > 0) {
        return "+" + this.textRunning.diffWeek;
      } else if (this.textRunning.diffWeek < 0) {
        return this.textRunning.diffWeek.toString();
      } else if (this.textRunning.diffWeek == 0) {
        return "0";
      }
      return "laoding...";
    },
    textDayOfWeekRange() {
      // const dayOfWeekTH = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
      // if (this.textRunning.currentDayOfWeek == 1) {
      if (this.textRunning.currentDayOfWeek == "จ") {
        return "วันนี้";
      }
      // console.log(
      //   "dayOfWeekTH: ",
      //   dayOfWeekTH,
      //   this.textRunning.currentDayOfWeek
      // );
      // return "จ-" + dayOfWeekTH[this.textRunning.currentDayOfWeek];
      return "จ-" + this.textRunning.currentDayOfWeek;
    },

    //--range-date-comp
    dateRangeStart() {
      return dayjs()
        .subtract(1, "year")
        .format("YYYY-MM-DD");
    },
    dateRangeLimit() {
      // return dayjs().format("YYYY-MM-DD");
      return this.nowDate;
    },
    nowDate() {
      return dayjs().format("YYYY-MM-DD");
    }
  },
  watch: {
    // textRunning: {
    //   handler(val) {
    //     // console.log("new-textRunning is: ", this.textRunning);
    //     console.log("new-textRunning is: ", val);
    //   },
    //   deep: true
    // },

    showDateRange: {
      handler(newDateRange) {
        // console.log(newDateRange);
        //check startDate
        //check endDate
        if (newDateRange.showEndDate < newDateRange.showStartDate) {
          this.endDate_validator = false;
        } else if (newDateRange.showEndDate > this.nowDate) {
          this.endDate_validator = false;
          return;
        } else {
          this.endDate_validator = true;
        }

        if (newDateRange.showStartDate > this.nowDate) {
          this.startDate_validator = false;
          return;
        } else {
          this.startDate_validator = true;
        }
        this.reloadDateCustom();
      },
      deep: true
    }
    // showEndDate: function(newEndDate) {
    //   if (newEndDate < this.showStartDate) {
    //     this.endDate_validator = false;
    //   }
    // },
    // showStartDate: function(newEndDate) {
    //   if (newEndDate > this.showEndDate) {
    //     this.endDate_validator = false;
    //   }
    // },
    // showDateMode: function(newMode, oldMode) {
    //   // DEBUG-only
    //   console.log("newMode", newMode);
    //   console.log("oldMode", oldMode);

    //   // // reset-date-range
    //   // if (newMode != "custom") {
    //   //   this.showStartDate = null;
    //   //   this.showEndDate = null;
    //   // }
    // }
  },
  methods: {
    // DEBUG-only
    // closePopup(val) {
    //   this.map.Overlays.remove(this.popup01);
    //   // console.log("(on-click)closePopup: ", val);
    // },
    tryClick(val) {
      // //--write-comp
      // this.xoxo = document.getElementById("xoxo");
      // // this.xoxo = document.getElementsByClassName("xx01")[0];
      // // this.xoxo.innerHTML = document.getElementById("hideComp").innerHTML;
      // // let hc = document.getElementById("hideComp");
      // // this.xoxo.appendChild(hc);
      // this.hc = document.getElementById("hideComp");
      // this.xoxo.appendChild(this.hc);
      // this.isShowXoxo = true;
      // console.log("tryClick: ", val);
      console.log("(on-load)tryClick: ", val);
    },

    // // --popup-methods
    // popupClear() {
    //   this.map.Overlays.clear();
    //   console.log("popupClear...");
    // },

    // --API-methods
    reloadApi(params) {
      // let pathApi = "http://localhost:8000/summary";
      // this.axios.get(this.pathApi).then(response => {
      this.axios
        .post(this.pathApiURL + this.pathSummaryMap, params)
        .then(response => {
          this.lastDataRes = response.data;
          // this.axios.get(this.pathApi, { params: params }).then(response => {
          // console.log("response.data", response.data);
          // window.dataSummary = response.data;
          this.markerRender(response.data.summaryResult, "main");
        });
    },

    // --user-Event
    changeFilterWarningIcon() {
      // reRender
      this.markerRender(this.lastDataRes.summaryResult, "main");
    },
    async renderTextRunning(data) {
      if (data.dataStatus == "err") {
        this.textRunning.total = 0;
      } else {
        this.textRunning = data.dataRes;
      }
    },
    async reloadDataFromApi() {
      //--reload-textRunning
      this.axios.get(this.pathApiURL + this.pathTextRunning).then(response => {
        console.log("summary-textRunning: ", response.data);
        this.renderTextRunning(response.data);
      });

      //--reload-data
      this.showDateMode = ENUM_FILTER_MODE.default;
      let params = {
        mode: this.showDateMode
      };

      const response = await this.axios.post(
        this.pathApiURL + this.pathSummaryMap,
        params
      );
      this.lastDataRes = response.data;
      console.log("response.data: ", response.data);

      //--reRender
      this.markerRender(response.data.summaryResult, "main");
    },
    async onUserUpdateUrl() {
      //--callAPI

      let paramsSetUrl = {
        "sheet-url": this.envSheetUrl
      };

      const resSetUrl = await this.axios.post(
        this.pathApiURL + this.pathSetUrl,
        paramsSetUrl
      );
      //--if-err: UpdateUrl
      if (resSetUrl.data.dataStatus == "err") {
        console.log("res-err: ", resSetUrl.data.message);
        //--inValidate input
        this.validateSheetUrl = false;
        return;
      }

      await this.reloadDataFromApi();

      //--clear sheetUrl
      this.envSheetUrl = "";
    },
    async onUserSyncLocation() {
      //--callAPI
      this.showSyncErr = false;
      this.showSyncLoading = true;

      try {
        const res = await this.axios.get(
          this.pathApiURL + this.pathSyncLocation
        );
        this.showSyncLoading = false;
        //--if-err: SyncLocation
        if (res.data.dataStatus == "err") {
          console.log("res-err: ", res.data.message);
          //--inValidate input
          this.showSyncErr = true;
          return;
        }

        await this.reloadDataFromApi();
      } catch (error) {
        this.showSyncLoading = false;
        this.showSyncErr = true;
        console.log("API-err: ", error);
      }
    },
    async onUserSyncData() {
      //--callAPI
      this.showSyncErr = false;
      this.showSyncLoading = true;

      try {
        const res = await this.axios.get(this.pathApiURL + this.pathSyncData);
        this.showSyncLoading = false;
        console.log("res: ", res);
        //--if-err: SyncData
        if (res.data.dataStatus == "err") {
          console.log("res-err: ", res.data.message);
          //--inValidate input
          this.showSyncErr = true;
          return;
        }
        await this.reloadDataFromApi();
      } catch (error) {
        this.showSyncLoading = false;
        this.showSyncErr = true;
        console.log("API-err: ", error);
      }
    },
    changeMode() {
      // TODO: reLoad-data,reRender-map
      // TODO: call-API
      switch (this.showDateMode) {
        // case "all":
        case ENUM_FILTER_MODE.all:
          this.reloadDateAll();
          break;
        // case "today":
        case ENUM_FILTER_MODE.today:
          this.reloadApi({
            mode: "today"
          });
          console.log("call-API: where-date=today");

          //--date from client
          // const today = new Date();
          // const date =
          //   today.getFullYear() +
          //   "-" +
          //   (today.getMonth() + 1) +
          //   "-" +
          //   today.getDate();
          //   console.log('call-API: where-date='+date);
          break;
        // case "month":
        case ENUM_FILTER_MODE.month:
          this.reloadApi({
            mode: "month"
          });
          break;
        // case "custom":
        case ENUM_FILTER_MODE.custom:
          this.reloadDateCustom();
          break;

        default:
          break;
      }
      // this.markerRender(window.dataSummary.summaryResult, "main");
      console.log("reLoad - data, reRender - map");
    },
    reloadDateAll() {
      let params = {
        mode: "all"
      };

      this.reloadApi(params);
    },
    reloadDateCustom() {
      let params = {
        mode: "custom",
        rangeStart: this.showDateRange.showStartDate,
        rangeEnd: this.showDateRange.showEndDate
      };

      if (this.showDateMode == ENUM_FILTER_MODE.custom) {
        // check Date_validator before-call-API
        if (this.startDate_validator && this.endDate_validator) {
          // TODO: load-data from-API
          // this.markerRender(window.dataSummary.summaryResult, "main");
          // this.reloadApi();
          this.reloadApi(params);
        }
      }
    },
    // zoomAndLinkTo(subUnitId) {
    //   let locationArr = "lon,lat";
    //   let zoomLevel = this.zoomLevelIsSubUnit;

    //   console.log("subUnitId:", subUnitId);
    //   console.log("locationArr:", locationArr);
    //   console.log("zoomLevel:", zoomLevel);

    //   // TODO: --longdo-map change zoomLevel
    //   // --(duplicate-in-markerRenderSub)longdo-map position to-location
    // },

    // --filter-methods
    toggleMoreInfo() {
      // console.log('toggleMoreInfo...');
      this.toggleShowMorwInfo = !this.toggleShowMorwInfo;
    },
    toggleSetting() {
      // console.log('toggleSetting...');
      this.toggleShowSettings = !this.toggleShowSettings;
    },

    // --renderMap-methods
    clearMap() {
      this.map.Overlays.clear();
    },
    renderLopburi() {
      // --clearMap
      this.clearMap();

      this.edgeObjectLopburi = new window.longdo.Overlays.Object("16", "IG", {
        lineColor: "#888", //สีของเส้น
        // fillColor: null //สีพื้นหลัง
        fillColor: "rgba(255, 0, 0, 0.1)", //สีพื้นหลัง
        // fillColor: "#777", //สีพื้นหลัง
        // clickable: false
        weight: 0.0,
        popup: null,
        labelOptions: null,
        label: null,
        detail: null,
        title: null
      });
      this.map.Overlays.load(this.edgeObjectLopburi); // create a Bangkok district geometry
    },
    // --maker-methods
    markerRender(listLocation, dataType) {
      //dataType = [main/sub]
      console.log("markerRender...");
      if (listLocation.length < 1) {
        console.log("listLocation.length: ", 0);
      }

      // --reRenderLopburi
      this.renderLopburi();

      // --loop-addMarker
      for (let i = 0; i < listLocation.length; i++) {
        const element = listLocation[i];
        // console.log("element:", element);
        let total = element.totalPoltahan + element.totalKamrampol;
        let isHasCase = total > 0;
        if (!isHasCase) {
          // ไม่มีคนไข้-ไม่ต้องเตือน
          continue;
        }
        if (dataType == ENUM_RENDER_MARKER_TYPE.main) {
          let markerTemplateMain = this.markerGenerateMainTemplate(element);
          if (markerTemplateMain == false) {
            // break; //ต้องได้ string
            return false;
          }
          this.listMarkerRendered.push(markerTemplateMain); //keep for-clear
          this.map.Overlays.add(markerTemplateMain);
        } else {
          // is ENUM_RENDER_MARKER_TYPE.sub
          // TODO: render-from-sunTemplate
        }
      }
      return true;
    },
    markerGenerateMainTemplate(optionParams) {
      if (!optionParams) {
        console.log(
          "Err: markerGenerateMainTemplate() ->optionParams is-required."
        );
        return false; //optionParams is-required.
      }
      //--select-iconType

      let fullname = "";
      if (optionParams.fullname) {
        // fullname = optionParams.fullname
        fullname = `(ชื่อเต็ม: ${optionParams.fullname})`;
      }
      let total = optionParams.totalPoltahan + optionParams.totalKamrampol;
      let hasManyCase = total >= +this.envStateFilterWarningIcon;
      let iconMarker = "";
      if (hasManyCase) {
        const enum_zoom = [7, 7, 12, 16];
        const iconSize = enum_zoom[this.zoomIconSize];
        iconMarker =
          '<div class="w-' +
          iconSize +
          " h-" +
          iconSize +
          ' p-0"><img class="align-top w-' +
          iconSize +
          " h-" +
          iconSize +
          ' " src="/assets/images/confirmed.svg" alt="pic" /></div>';
      } else {
        const enum_zoom = [3, 3, 6, 11];
        const iconSize = enum_zoom[this.zoomIconSize];
        iconMarker =
          '<div class="w-' +
          iconSize +
          " h-" +
          iconSize +
          ' flex items-center justify-center"> <img class="align-topw-' +
          iconSize +
          " h-" +
          iconSize +
          ' " src="/assets/images/dot.svg" alt="pic" /> </div>';
      }
      // let dateRange_type = `
      let dateRange_value = "";
      if (this.showDateMode == ENUM_FILTER_MODE.custom) {
        // dateRange_value = "2020-01-01 - 2020-02-02";
        dateRange_value =
          this.showDateRange.showStartDate +
          " - " +
          this.showDateRange.showEndDate;
      } else {
        switch (this.showDateMode) {
          case ENUM_FILTER_MODE.all:
            dateRange_value = "ทั้งหมด";
            break;
          case ENUM_FILTER_MODE.today:
            dateRange_value = "วันนี้";
            break;
          case ENUM_FILTER_MODE.week:
            dateRange_value = "1 อาทิตย์ย้อนหลัง";
            break;
          case ENUM_FILTER_MODE.month:
            dateRange_value = "เดือนนี้";
            break;

          default:
            dateRange_value = "Error.";
            break;
        }
      }
      let dateRange = `
      <li>ช่วง: ${dateRange_value}</li>
      `;
      let summarySubUnit = "";
      for (let i = 0; i < optionParams.sub_units.length; i++) {
        const subUnit = optionParams.sub_units[i];
        const totalSubUnit = subUnit.totalPoltahan + subUnit.totalKamrampol;
        summarySubUnit += `
      <li>- ${subUnit.title}: รวม ${totalSubUnit}(พลทหาร: ${subUnit.totalPoltahan},พลเรือน: ${subUnit.totalKamrampol})</li>`;
      }
      // let detailHtml = `<ul>
      // ${fullname}
      let detailHtml = `<ul>
      <li>Total: ${total}</li>
      <li>พลทหาร (ราย): ${optionParams.totalPoltahan}</li>
      <li>พลเรือน/กำลังพลและครอบครัว: ${optionParams.totalKamrampol}</li>
      <br />
      <li>หน่วยย่อย:: </li>
      ${summarySubUnit}
      <br />
      ${dateRange}
      </ul>`;
      if (optionParams.is_mockup_location == 1) {
        iconMarker += "(Mockup)";
        // iconMarker = ".";
      }
      // location: "14.91111, 100.761219"
      let locationArr = optionParams.location.split(", ");
      let markerOptions = {
        title: optionParams.title + fullname,
        // title: "Custom Marker",
        // detail: "Drag me",
        // detail: "<b>Marker</b>",
        detail:
          // '<div class="xx01"><div class="close-xx01" onclick="closePopup(\'close-xx01\')">close-xx01</div><img src="/icons/loading/Eclipse-1s-200px.svg" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>',
          detailHtml,
        // visibleRange: { min: 7 },
        // visibleRange: { min: 7, max: 12 },
        visibleRange: { min: 7, max: 20 },
        // draggable: true,
        // weight: window.longdo.OverlayWeight.Top,

        icon: {
          // TODO: ครอบด้วย icon-home
          // html: "<b>Marker</b>",
          html:
            // '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>',
            iconMarker,
          offset: { x: 18, y: 21 }
        }
      };
      // console.log("iconMarker: ", iconMarker);
      let markerTemplateMain = new window.longdo.Marker(
        // { lon: 99.35, lat: 14.25 },
        {
          lon: locationArr[1],
          lat: locationArr[0]
        },
        markerOptions
        // this.centerLocation,
        // {
        //   title: optionParams.title,
        //   // title: "Custom Marker",
        //   // detail: "Drag me",
        //   // detail: "<b>Marker</b>",
        //   detail:
        //     // '<div class="xx01"><div class="close-xx01" onclick="closePopup(\'close-xx01\')">close-xx01</div><img src="/icons/loading/Eclipse-1s-200px.svg" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>',
        //     detailHtml,
        //   visibleRange: { min: 7, max: 12 },
        //   // draggable: true,
        //   // weight: window.longdo.OverlayWeight.Top,

        //   icon: {
        //     // TODO: ครอบด้วย icon-home
        //     // html: "<b>Marker</b>",
        //     html:
        //       // '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>',
        //       iconMarker,
        //     offset: { x: 18, y: 21 }
        //   }
        // }
      );
      // console.log("markerOptions: ", markerOptions, locationArr);

      return markerTemplateMain;
    },
    locateMe() {
      // console.log("locateMe...");
      //locateMe

      // map.location(longdo.LocationMode.Geolocation);
      // this.$refs.map007.map.location(window.longdo.LocationMode.Geolocation);
      // this.map.location(window.longdo.LocationMode.Geolocation);
      // this.map.location({lon: 100.665269, lat: 14.849835}, true); // 15.095051, 100.826721
      this.map.location(this.centerLocation, true); // 15.095051, 100.826721
    },
    toggleIconDetail() {
      this.isShowIconDetail = !this.isShowIconDetail;
    },
    onWindowLoad() {
      // // --debound
      // let currentTime = new Date().getTime();
      // if (currentTime - this.lastReload < 1000) {
      //   return;
      // }
      // this.lastReload = currentTime;

      console.log("onLoad...");
      // this.loadedWindow = true;
      // console.log("loadedWindow", this.loadedWindow);

      // init();
      // initMap-firstest...
      window.longdoMapDev = new window.longdo.Map({
        placeholder: document.getElementById("longdo-map"),
        // ui: window.longdo.UiComponent.Mobile //Full,Compact,Mobile,None
        ui: window.longdo.UiComponent.Full //Full,Compact,Mobile,None
      });
      this.map = window.longdoMapDev;

      //--set-center
      // this.map.location({ lon: 100, lat: 16 }, true); // go to 100, 16 when created map
      this.map.location(this.centerLocation, true); // 15.095051, 100.826721

      //--handle-event zoom
      // https://map.longdo.com/docs/javascript/events/mapevents
      let selfMap = this.map;
      let self = this;
      // this.map.Event.bind(
      //   "zoomRange",
      //   function() {
      //     const currentZoomRange = selfMap.zoomRange(); // Current zoom range
      //     console.log("event-currentZoomRange: ", currentZoomRange);
      //   }.bind(selfMap)
      // );
      this.map.Event.bind(
        "zoom",
        function() {
          const currentZoom = selfMap.zoom(); // Get the zoom value
          let isChange = false;
          console.log("event-currentZoom: ", currentZoom);
          if (currentZoom < 11) {
            if (ENUM_ICON_SIZE.x1 != self.zoomIconSize) {
              isChange = true;
            }
            self.zoomIconSize = ENUM_ICON_SIZE.x1;
          } else if (currentZoom < 14) {
            if (ENUM_ICON_SIZE.x2 != self.zoomIconSize) {
              isChange = true;
            }
            self.zoomIconSize = ENUM_ICON_SIZE.x2;
          } else {
            if (ENUM_ICON_SIZE.x3 != self.zoomIconSize) {
              isChange = true;
            }
            self.zoomIconSize = ENUM_ICON_SIZE.x3;
          }
          if (!!self.lastDataRes && isChange) {
            self.markerRender(self.lastDataRes.summaryResult, "main");
            // console.log("reRender-with: ", self.lastDataRes);
            console.log("reRender-with: ", self.lastDataRes.summaryResult);
          }
          // console.log("new-self.zoomIconSize: ", self.zoomIconSize);
        }.bind(selfMap, self)
      );

      //--renderLopburi
      this.renderLopburi();

      //--zoom-level
      this.map.zoom(9, true);
      this.map.zoomRange({ min: 9, max: 19 });

      // NOTE: DEBUG-only
      // let firstResult = dataSummary.summaryResult[0];
      // this.markerRender([firstResult], ENUM_RENDER_MARKER_TYPE.main);

      // this.markerRender(
      //   window.dataSummary.summaryResult,
      //   ENUM_RENDER_MARKER_TYPE.main
      // );
      this.reloadApi();
    },
    // gotoMarkedSub(id) {
    //   console.log("gotoMarkedSub(id): " + id);
    // },
    tryOnly() {
      // // --make-popup
      // this.map.location({ lon: 99.35, lat: 14.25 }, true);
      // let markerSub = new window.longdo.Marker(
      //   // { lon: 99.35, lat: 14.25 },
      //   this.centerLocation,
      //   {
      //     title: "Custom Marker",
      //     // detail: "Drag me",
      //     // detail: "<b>Marker</b>",
      //     detail:
      //       '<div class="xx01"><div class="close-xx01" onclick="closePopup(\'close-xx01\')">close-xx01</div><img src="/icons/loading/Eclipse-1s-200px.svg" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>',
      //     visibleRange: { min: 7, max: 9 },
      //     // draggable: true,
      //     // weight: window.longdo.OverlayWeight.Top,

      //     icon: {
      //       // html: "<b>Marker</b>",
      //       html:
      //         '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>',
      //       // html: '<img src="/img/confirmed.d6f6f2b7.svg" width="16" height="16" alt="pic" class="align-top">',
      //       offset: { x: 18, y: 21 }
      //     }
      //     // clickable: true
      //     // popup: this.popup01
      //     // popup: {
      //     //   // delegateloadHtml: () => {
      //     //   //   console.log("delegateloadHtml");
      //     //   // },
      //     //   closable: true,
      //     //   size: "100px",
      //     //   //html
      //     //   // html: '<div @click="tryClick(\'xx01\')" class="xx01">popup</div>'
      //     //   // html: '<div v-on:click="tryClick(\'xx01\')" class="xx01">popup</div>'
      //     //   // html: '<div onclick="tryClick(\'xx01\')" class="xx01">popup</div>'
      //     //   // html: '<div onload="tryClick(\'xx01\')" class="xx01">popup</div>'
      //     //   // html: '<div class="xx01"><img src="https://www.w3schools.com/jsref/w3javascript.gif" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>'
      //     //   html:
      //     //     '<div class="xx01"><div class="close-xx01" onclick="closePopup(\'close-xx01\')">close-xx01</div><img src="/icons/loading/Eclipse-1s-200px.svg" onload="tryClick(\'xx01\')" width="100" height="132">popup</div>'
      //     //   // html: '<div cls style="background: #eeeeff;">popup</div>'
      //     //   // html: PopupHasSub.$mounted
      //     //   // html: '<div onload="console.log(\'Loaded!\')" style="background: #eeeeff;">popup</div>'
      //     //   // html: '<div cls style="background: #eeeeff;"><PopupHasSub listSubObj="[1,2,3]" />xxx</div>'
      //     //   // html:
      //     //   //   '<div style="background: #eeeeff;"><popup-has-sub id="popup-click" :listSubObj="[1,1,2]" />xxx</div>'
      //     //   // html:
      //     //   //   '<div onload="myVue.$children[0].reRender()" style="background: #eeeeff;"><popup-has-sub :listSubObj="[1,1,2]" />xxx</div>'
      //     //   // html: '<PopupHasSub />'
      //     // }
      //   }
      // );
      // this.marker01 = markerSub;
      // this.map.Overlays.add(markerSub);
      // //--

      // //--marked-clickable
      // // let markerMain = new window.longdo.Marker(
      // //   { lon: 99.35, lat: 14.25 },
      // //   {
      // //     title: "marked-clickable",
      // //     icon: {
      // //       // html: "<b>Marker</b>",
      // //       html:
      // //         '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>',
      // //       // html: '<img src="/img/confirmed.d6f6f2b7.svg" width="16" height="16" alt="pic" class="align-top">',
      // //       offset: { x: 18, y: 21 }
      // //     },
      // //     clickable: true
      // //   }
      // // );
      // // var marker1 = new window.longdo.Marker({ lon: 100.5, lat: 13.7 });
      // var marker1 = new window.longdo.Marker({ lon: 99.35, lat: 14.25 });
      // marker1.message = "Click me";
      // this.map.Overlays.add(marker1);
      // let self = this;
      // this.map.Event.bind("overlayClick", function(overlay) {
      //   if (overlay == marker1) {
      //     // alert(overlay.message);
      //     let markSubId = 12;
      //     self.gotoMarkedSub(markSubId);
      //   }
      // });
      // //--

      // //--popup-location
      // let popup1 = new window.longdo.Popup(
      //   // { lon: 99, lat: 14 },
      //   { lon: 100.665269, lat: 14.849835 },
      //   {
      //     title: "Popup",
      //     detail: "<p class='xx'>Simple popup</p>"
      //   }
      // );
      // // this.$refs.map007.map.Overlays.add(popup1);
      // this.map.Overlays.add(popup1);
      //--

      //--write-comp
      this.xoxo = document.getElementById("xoxo");
      // this.xoxo = document.getElementsByClassName("xx01")[0];
      // this.xoxo.innerHTML = document.getElementById("hideComp").innerHTML;
      // let hc = document.getElementById("hideComp");
      // this.xoxo.appendChild(hc);
      this.hc = document.getElementById("hideComp");
      this.xoxo.appendChild(this.hc);
      this.isShowXoxo = true;
      // this.xoxo.innerHTML =
      //   '<div > <popup-has-sub :key="uniqueMyCon" :list_sub_obj="[1,1,2]" /> </div>';
      // this.xoxo.innerHTML =
      //   '<div v-show="uniqueMyCon"> <popup-has-sub :list_sub_obj="[1,1,2]" /> </div>';
      // this.xoxo.innerHTML =
      //   '<div v-if="uniqueMyCon > 1"> <popup-has-sub /> </div>';
      // this.xoxo.innerHTML =
      //   '<popup-has-sub :key="uniqueMyCon" :listsubobj="[1,1,2]">xxx</popup-has-sub>';
      // this.xoxo.innerHTML =
      //   '<popup-has-sub :listsubobj="[1,1,2]">xxx</popup-has-sub>';
      // Vue.compile(this.xoxo.innerHTML);
      // this.xoxo.
      this.uniqueMyCon++;
      //--
    }
  },

  created() {
    window.tryClick = this.tryClick;
    window.closePopup = this.closePopup;
    window.markerRender = this.markerRender;
  },
  mounted() {
    console.log(process.env);
    // //--window-loaded
    if (!this.loadedWindow) {
      window.addEventListener("load", this.onWindowLoad);
    }
    // } else {
    //   this.onWindowLoad();
    //   console.log("re-call: this.onWindowLoad()");
    // }

    //--load-dataSummary
    // let pathApi = "http://localhost:8000/summary";
    // this.axios.get(pathApi).then(response => {
    //   console.log("response.data", response.data);
    //   window.dataSummary = response.data;
    // });
    // this.reloadApi();

    //--log-local-json
    // console.log("dataSummary", dataSummary);
    // window.dataSummary = dataSummary;

    //--init-date-form
    this.showDateRange.showStartDate = this.nowDate;
    this.showDateRange.showEndDate = this.nowDate;

    //--load textRunning
    this.axios.get(this.pathApiURL + this.pathTextRunning).then(response => {
      console.log("summary-textRunning: ", response.data);
      // this.textRunning = response.data;
      this.renderTextRunning(response.data);

      // this.textRunning = {
      //   total: response.data.total,
      //   pol: response.data.pol,
      //   kam: response.data.kam,
      //   date_start: response.data.date_start,
      //   date_end: response.data.date_end
      // };

      // this.textRunning.total = response.data.total;
      // this.textRunning.pol = response.data.pol;
      // this.textRunning.kam = response.data.kam;
      // this.textRunning.date_start = response.data.date_start;
      // this.textRunning.date_end = response.data.date_end;
    });

    // --update dataSummary--to--Vue-data
    // let grouped = {};
    // dataSummary.summaryMap.forEach(function(a) {
    //   let mainUnit_id = dataSummary.subUnits.find(
    //     x => x.id == a.dim_sub_unit_id
    //   ).dim_main_unit_id;
    //   let mainUnit_title = dataSummary.mainUnits.find(x => x.id == mainUnit_id)
    //     .title;
    //   grouped[a.mainUnit_id] = grouped[a.mainUnit_id] || [];
    //   grouped[a.mainUnit_id].push({ title: a.mainUnit_title, id: a.id });
    // });

    //
  }
};
</script>

<style>
/* .icon img {
  width: 60px;
  display: block;
  margin: 0 auto;
} */

.icon {
  position: relative;
  display: inline-block;
  /* margin: 30px;
  margin-top: 65px; */
}

.icon span {
  position: absolute;
  display: table;
  top: -35px;
  background: #333;
  color: #fff;
  border-radius: 3px;
  padding: 3px 6px;
  opacity: 0;
  transition: 0.2s all ease-out;
}

.icon img:hover ~ * {
  opacity: 1;
}

.icon span:after {
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
  top: 26px;
  left: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}
</style>
